<template>
  <div>
    <form @submit.prevent="submitFrom1">
      <div class="d-flex w-100 gap-16 margin-bottom-16">
        <div class="input-icon-container">
          <div class="input-icon">
            <Icon name="new-form-user" />
          </div>

          <input
            type="text"
            class="new-form-control new-form-control-includes-icon"
            id="firstName"
            name="firstName"
            :placeholder="
              $store.state.creatorSignupPage?.Form1?.placeholderFirstName
            "
            v-model="FormData.first_name"
            pattern="[A-Za-z]+"
            :class="{ 'is-invalid': FromErrorFlags.first_name }"
            @input="clearError('first_name')"
          />
        </div>
        <div class="input-icon-container">
          <div class="input-icon">
            <Icon name="new-form-user" />
          </div>

          <input
            type="text"
            class="new-form-control new-form-control-includes-icon"
            id="lastName"
            name="lastName"
            :placeholder="
              $store.state.creatorSignupPage?.Form1?.placeholderLastName
            "
            pattern="[A-Za-z]+"
            v-model="FormData.last_name"
            :class="{ 'is-invalid': FromErrorFlags.last_name }"
            @input="clearError('last_name')"
          />
        </div>
      </div>
      <div class="input-icon-container margin-bottom-16">
        <div class="input-icon">
          <Icon name="new-form-email" />
        </div>

        <input
          type="text"
          class="new-form-control new-form-control-includes-icon"
          id="email"
          name="email"
          :placeholder="
            $store.state.creatorSignupPage?.NewCForm1?.FormStep1
              ?.emailPlaceholder
          "
          v-model="FormData.email"
          :class="{ 'is-invalid': FromErrorFlags.email }"
          @input="clearError('email')"
        />
      </div>
      <div class="d-flex w-100 gap-16 margin-bottom-16">
        <div class="new-signup-form-select-container-ver2" style="height: 100%">
          <select
            class="new-signup-form-dropdown country-code-dropdown"
            v-model="FormData.country_code_mob"
            :class="{ 'is-invalid': FromErrorFlags.country_code_mob }"
            @change="clearError('country_code_mob')"
          >
            <option
              v-for="nationality in $store.state.nationalities"
              :value="nationality.code"
              :key="'mob-country-code-' + nationality.id"
              :data-text="
                nationality.country_name + ' (' + nationality.code + ')'
              "
            >
              {{ nationality.country_name }} ({{ nationality.code }})
            </option>
          </select>
        </div>
        <input
          type="text"
          class="new-form-control"
          id="mobile number"
          name="mobile number"
          :placeholder="
            $store.state.creatorSignupPage?.NewCForm1?.FormStep1
              ?.mobilePlaceHolder
          "
          v-model="FormData.mobile_number"
          inputmode="numeric"
          pattern="\d+"
          :class="{ 'is-invalid': FromErrorFlags.mobile_number }"
          @input="clearError('mobile_number')"
        />
      </div>
      <div class="d-flex w-100 gap-16 margin-left-08 checkbox-label-container">
        <div class="checkbox-container">
          <input
            type="checkbox"
            id="custom-checkbox"
            class="custom-checkbox"
            v-model="useSameNumberForWhatsapp"
            @change="syncWhatsappFields"
          />
          <label for="custom-checkbox" class="checkbox-label"></label>
        </div>
        <label for="custom-checkbox" class="whatsapp-text">{{
          $store.state.creatorSignupPage?.Form2?.useSameNumberWhatsappText
        }}</label>
      </div>
      <div class="d-flex w-100 gap-16">
        <div class="new-signup-form-select-container-ver2" style="height: 100%">
          <select
            class="new-signup-form-dropdown country-code-dropdown-whatsapp"
            v-model="FormData.country_code_whats"
            :class="{ 'is-invalid': FromErrorFlags.country_code_whats }"
            :disabled="useSameNumberForWhatsapp"
          >
            <option
              v-for="nationality in $store.state.nationalities"
              :value="nationality.code"
              :key="'whats-country-code-' + nationality.id"
              :data-text="
                nationality.country_name + ' (' + nationality.code + ')'
              "
            >
              {{ nationality.country_name }} ({{ nationality.code }})
            </option>
          </select>
        </div>
        <input
          type="text"
          :class="[
            'new-form-control',
            { 'new-form-disabled-input': useSameNumberForWhatsapp },
            { 'is-invalid': FromErrorFlags.whatsapp_number },
          ]"
          inputmode="numeric"
          pattern="\d+"
          id="whatsapp number"
          name="whatsapp number"
          :placeholder="
            $store.state.creatorSignupPage?.Form2?.whatsappPlaceholder
          "
          v-model="FormData.whatsapp_number"
          @input="clearError('whatsapp_number')"
          :disabled="useSameNumberForWhatsapp"
        />
      </div>
    </form>
  </div>
</template>
<script>
import { axiosAPI } from "../../../../axiosClient";
import { ref } from "vue";
import { toast } from "vue3-toastify";
import Icon from "@/assets/icons/index.vue";
//import CustomCountryDropdown from "@/components/Common/CustomCountryCodesDropdown.vue";

const phoneCheckCount = ref(0);

export default {
  name: "CreatorForm1Ver2",
  components: {
    Icon,
    //CustomCountryDropdown,
  },
  data() {
    return {
      phoneCheckCount: phoneCheckCount,
      useSameNumberForWhatsapp: false,
      FormData: {
        first_name: "",
        last_name: "",
        email: "",
        country_code_mob: "+971",
        mobile_number: "",
        country_code_whats: "+971",
        whatsapp_number: "",
      },
      FromErrorFlags: {
        first_name: false,
        last_name: false,
        email: false,
        mobile_number: false,
        whatsapp_number: false,
      },
      FromErrorMessages: {
        first_name: [],
        last_name: [],
        email: [],
        mobile_number: [],
        whatsapp_number: [],
      },
    };
  },
  watch: {
    "$store.state.nationalities": {
      handler() {
        this.$nextTick(() => {
          this.updateDropdownDisplay();
        });
      },
      deep: true,
    },

    // Watch the form data country code
    "FormData.country_code_mob"(newVal) {
      this.$nextTick(() => {
        this.updateDropdownDisplay();
      });
      if (this.useSameNumberForWhatsapp) {
        this.FormData.country_code_whats = newVal;
      }
    },
    "FormData.country_code_whats"() {
      this.$nextTick(() => {
        this.updateDropdownDisplay();
      });
    },
    // Watch for changes in mobile_number
    "FormData.mobile_number"(newVal) {
      if (this.useSameNumberForWhatsapp) {
        this.FormData.whatsapp_number = newVal;
      }
    },
    // Watch for changes in country_code_mob
    /* "FormData.country_code_mob"(newVal) {
      if (this.useSameNumberForWhatsapp) {
        this.FormData.country_code_whats = newVal;
      }
    },*/
    // Watch for changes in useSameNumberForWhatsapp
    useSameNumberForWhatsapp(newVal) {
      this.clearError("whatsapp_number");
      if (newVal) {
        // Sync WhatsApp number and country code if checkbox is checked
        this.FormData.whatsapp_number = this.FormData.mobile_number;
        this.FormData.country_code_whats = this.FormData.country_code_mob;
      } else {
        // Clear WhatsApp number and country code if checkbox is unchecked
        this.FormData.whatsapp_number = "";
        this.FormData.country_code_whats = "+971";
      }
    },
  },
  mounted() {
    // Initial update after component is mounted
    this.$nextTick(() => {
      this.updateDropdownDisplay();
      this.setupDropdownListeners();
    });
  },
  updated() {
    // Update display after any component updates
    this.updateDropdownDisplay();
  },
  beforeUnmount() {
    this.removeDropdownListeners();
  },
  methods: {
    checkMobileNumberExists(data, type) {
      if (!data) return Promise.resolve(false); // No data, assume invalid.

      phoneCheckCount.value++;
      let FormData = {
        mobile: data,
      };

      if (type === "mobile") {
        this.FromErrorFlags.mobile_number = false;
        this.FromErrorMessages.mobile_number = [];
        FormData["country_code"] = this.FormData.country_code_mob;
      } else {
        this.FromErrorFlags.whatsapp_number = false;
        this.FromErrorMessages.whatsapp_number = [];
        FormData["country_code"] = this.FormData.country_code_whats;
      }

      return axiosAPI
        .post(`v1/creator/check-mobile`, FormData)
        .then((response) => {
          phoneCheckCount.value--;

          if (response.data && response.data.status) {
            // Valid mobile or WhatsApp number
            return true;
          } else {
            if (type === "mobile") {
              this.FromErrorFlags.mobile_number = true;
              this.FromErrorMessages.mobile_number = response.data.msg_slug;
            } else {
              this.FromErrorFlags.whatsapp_number = true;
              this.FromErrorMessages.whatsapp_number = response.data.msg_slug;
            }

            if (this.$store.state.errorMessges) {
              toast.error(
                this.$store.state.errorMessges[response.data.msg_slug]
              );
            }
            return false;
          }
        })
        .catch(() => {
          phoneCheckCount.value--;

          if (type === "mobile") {
            this.FromErrorFlags.mobile_number = true;
          } else {
            this.FromErrorFlags.whatsapp_number = true;
          }

          return false;
        });
    },
    updateDropdownDisplay() {
      // Handle both dropdowns dynamically
      const dropdownClasses = [
        ".country-code-dropdown",
        ".country-code-dropdown-whatsapp",
      ];
      dropdownClasses.forEach((className) => {
        const dropdowns = document.querySelectorAll(className);
        dropdowns.forEach((dropdown) => {
          if (dropdown.selectedIndex > 0) {
            const selectedOption = dropdown.options[dropdown.selectedIndex];
            selectedOption.text = selectedOption.value; // Show only code
          }
        });
      });
    },

    handleDropdownOpen(event) {
      const dropdown = event.target;
      Array.from(dropdown.options).forEach((option) => {
        if (option.dataset.text) {
          option.text = option.dataset.text; // Restore full text
        }
      });

      if (dropdown.selectedIndex > 0) {
        dropdown.options[dropdown.selectedIndex].text = dropdown.value; // Keep code for selected
      }
    },

    handleDropdownChange(event) {
      const dropdown = event.target;
      const selectedOption = dropdown.options[dropdown.selectedIndex];
      selectedOption.text = dropdown.value; // Ensure only code is shown
    },
    setupDropdownListeners() {
      const dropdownClasses = [
        ".country-code-dropdown",
        ".country-code-dropdown-whatsapp",
      ];
      dropdownClasses.forEach((className) => {
        const dropdowns = document.querySelectorAll(className);
        dropdowns.forEach((dropdown) => {
          dropdown.addEventListener("mousedown", this.handleDropdownOpen);
          dropdown.addEventListener("change", this.handleDropdownChange);
        });
      });
    },

    removeDropdownListeners() {
      const dropdownClasses = [
        ".country-code-dropdown",
        ".country-code-dropdown-whatsapp",
      ];
      dropdownClasses.forEach((className) => {
        const dropdowns = document.querySelectorAll(className);
        dropdowns.forEach((dropdown) => {
          dropdown.removeEventListener("mousedown", this.handleDropdownOpen);
          dropdown.removeEventListener("change", this.handleDropdownChange);
        });
      });
    },
    clearError(field) {
      if (this.FromErrorFlags[field]) {
        this.FromErrorFlags[field] = false;
        this.FromErrorMessages[field] = [];
      }
      // If the field is 'mobile_number' and the same number is used for WhatsApp, clear WhatsApp errors too
      if (field === "mobile_number" && this.useSameNumberForWhatsapp) {
        this.FromErrorFlags.whatsapp_number = false;
        this.FromErrorMessages.whatsapp_number = [];
      }
    },
    syncWhatsappFields() {
      if (this.useSameNumberForWhatsapp) {
        this.FormData.whatsapp_number = this.FormData.mobile_number;
        this.FormData.country_code_whats = this.FormData.country_code_mob;
      } else {
        this.FormData.whatsapp_number = "";
        this.FormData.country_code_whats = "+971";
      }
    },
    validateForm() {
      // Reset error flags and messages
      this.FromErrorFlags = {
        first_name: false,
        last_name: false,
        email: false,
        mobile_number: false,
        whatsapp_number: false,
      };
      this.FromErrorMessages = {
        first_name: [],
        last_name: [],
        email: [],
        mobile_number: [],
        whatsapp_number: [],
      };

      // Validation rules
      //const nameRegex = /^[A-Za-z]{2,}$/;
      const nameRegex = /^[A-Za-z\s]+$/;
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const phoneRegex = /^\d{8,15}$/;

      // First Name
      if (
        !this.FormData.first_name ||
        !nameRegex.test(this.FormData.first_name)
      ) {
        this.FromErrorFlags.first_name = true;
        this.FromErrorMessages.first_name.push("Enter a valid first name.");
      }

      // Last Name
      if (
        !this.FormData.last_name ||
        !nameRegex.test(this.FormData.last_name)
      ) {
        this.FromErrorFlags.last_name = true;
        this.FromErrorMessages.last_name.push("Enter a valid last name.");
      }

      // Email
      if (this.FormData.email) {
        this.FormData.email = this.FormData.email.trim();
      }

      if (!this.FormData.email || !emailRegex.test(this.FormData.email)) {
        this.FromErrorFlags.email = true;
        this.FromErrorMessages.email.push("Enter a valid email address.");
      }

      // Mobile Number
      // Trim the mobile number before validation
      if (this.FormData.mobile_number) {
        this.FormData.mobile_number = this.FormData.mobile_number.trim();
      }

      if (
        !this.FormData.mobile_number ||
        !phoneRegex.test(this.FormData.mobile_number)
      ) {
        this.FromErrorFlags.mobile_number = true;
        this.FromErrorMessages.mobile_number.push(
          "Enter a valid mobile number."
        );

        // Propagate errors to WhatsApp if using the same number
        if (this.useSameNumberForWhatsapp) {
          this.FromErrorFlags.whatsapp_number = true;
          this.FromErrorMessages.whatsapp_number = [
            ...this.FromErrorMessages.mobile_number,
          ];
        }
      }

      // Trim the WhatsApp number before validation (if not using the same as mobile)
      if (!this.useSameNumberForWhatsapp && this.FormData.whatsapp_number) {
        this.FormData.whatsapp_number = this.FormData.whatsapp_number.trim();
      }

      if (
        !this.useSameNumberForWhatsapp &&
        (!this.FormData.whatsapp_number ||
          !phoneRegex.test(this.FormData.whatsapp_number))
      ) {
        this.FromErrorFlags.whatsapp_number = true;
        this.FromErrorMessages.whatsapp_number.push(
          "Enter a valid WhatsApp number."
        );
      }

      // Consolidate all error messages
      const allErrors = Object.values(this.FromErrorMessages)
        .flat()
        .filter(Boolean);

      if (allErrors.length) {
        allErrors.forEach((error) => {
          toast.error(error, { toastId: error }); // Display each error in its own toast
        });
        return false; // Validation failed
      }
      return true; // Validation passed
    },
    syncWhatsappErrors() {
      if (this.useSameNumberForWhatsapp) {
        this.FromErrorFlags.whatsapp_number = this.FromErrorFlags.mobile_number;
        this.FromErrorMessages.whatsapp_number = [
          ...this.FromErrorMessages.mobile_number,
        ];
      }
    },
    watch: {
      // Sync errors when checkbox value changes
      useSameNumberForWhatsapp(newVal) {
        if (newVal) {
          this.syncWhatsappFields();
          this.syncWhatsappErrors();
        } else {
          this.FormData.whatsapp_number = "";
          this.FormData.country_code_whats = "";
          this.FromErrorFlags.whatsapp_number = false;
          this.FromErrorMessages.whatsapp_number = [];
        }
      },
    },
    async submitFrom1() {
      if (!this.validateForm()) {
        return null; // Explicitly indicate failure
      }

      // Validate mobile number
      const isMobileValid = await this.checkMobileNumberExists(
        this.FormData.mobile_number,
        "mobile"
      );

      // Validate WhatsApp number if not using the same as mobile
      let isWhatsAppValid = true;
      if (!this.useSameNumberForWhatsapp) {
        isWhatsAppValid = await this.checkMobileNumberExists(
          this.FormData.whatsapp_number,
          "whatsapp"
        );
      }

      // If either check fails, stop submission
      if (!isMobileValid || !isWhatsAppValid) {
        //toast.error("Please correct the validations errors.");
        return;
      }

      this.FormData.source = localStorage.getItem("utm_source") || "";
      this.FormData.utm_medium =
        (localStorage.getItem("utm_medium") || "") + "-new-creator-form";
      this.FormData.utm_campaign = localStorage.getItem("utm_campaign") || "";
      this.FormData.step = 1;

      // API Call
      return axiosAPI
        .post(`/v1/creator/signup`, this.FormData)
        .then((response) => {
          if (response.data && response.data.status) {
            const creatorId = response.data.data.creator_id;
            this.$emit("submitForm", {
              status: response.data.status,
              creator_id: creatorId,
            });
            return creatorId; // Return the creator ID
          } else {
            // Toast the message property of the response when status is false
            if (response.data && response.data.message) {
              toast.error(response.data.message || "Submission failed.", {
                toastId: response.data.message || "Submission failed.",
              });
            } else {
              toast.error("An unexpected error occurred.", {
                toastId: response.data.status,
              }); // Fallback message
            }
            this.$emit("submitForm", { status: response.data.status });
            return null; // Indicate failure
          }
        })
        .catch((error) => {
          this.$emit("submitForm", { status: false });
          if (error.response && error.response.data) {
            const errorMsgs = Object.values(error.response.data).flat();
            errorMsgs.forEach((error) => {
              toast.error(error, { toastId: error }); // Display each error in its own toast
            });
            //toast.error(errorMsgs.join("\n"));
          } else {
            toast.error("An error occurred. Please try again.", {
              toastId: "An error occurred. Please try again.",
            });
          }
          return null; // Indicate failure
        });
    },
  },
};
</script>
<style scoped>
.gap-16 {
  gap: 1rem;
}
.margin-left-08 {
  margin-left: 0.5rem;
}
.country-code-dropdown,
.country-code-dropdown-whatsapp {
  width: 105px;
  height: 54px;
  font-size: 18px;
  color: #7f7f7f;
  background-color: #f0f0f0;
}
.whatsapp-text {
  font-size: 15px;
  color: #4d4d4d;
}
.new-form-disabled-input {
  cursor: not-allowed;
  opacity: 0.5;
}

.is-invalid {
  outline: 1px solid red;
}

@media (max-width: 500px) {
  .whatsapp-text {
    font-size: 13.5px;
  }
}
@media (max-width: 363px) {
  .whatsapp-text {
    font-size: 12px;
  }
}

/* dropdown */

.new-signup-form-select-container-ver2 {
  position: relative;
  display: inline-flex;
  align-items: center;
}
</style>
